<div class="container-hero-pic">
  <img
    src="https://hhcwebsitedocs.blob.core.windows.net/hero/hasco-hero.jpeg"
    alt="what new"
  />
  <div class="caption-hero-pic">Park Package - Senior Living</div>
</div>

<div class="looking">
  We are showing a 2 bedroom with 2 bathroom home setup in a manufactured home
  community in Lynnwood. This corner lot with attached porch has great street
  presence and layout. The efficient Energy Star home is inexpensive to own and
  built to last as its Marlette strong. The Park rent is controlled by the State
  of Washington as well as Snohomish County and monthly fees are a low $650 per
  month including water, sewer and garbage! The Housing Authority of Snohomish
  County (HASCO) is offering this attractive 2 bed, 2 bathroom home in Lynnwood.
  This efficient Energy Star home is inexpensive to operate.<br /><br />
  You will not find this a city home in such a great location at such a great
  price.
</div>

<h2>What do you mean by "Park Package" in a Manufactured Home Community?</h2>

<p>
  This means that you purchase the new home and sign a lease for your rental
  location that includes all services provided by the Housing Authority. Call us
  for more details 425-353-5464.
  <br />
</p>

<h2>Why is Manufactured Housing a better option?</h2>

<p>
  Manufactured homes built in Washington State and then shipped by barge
  directly to you avoids all of the downsides of site building in Alaska.
  Climate is not a factor and building materials are sourced by a factory by the
  train load. Homes are built to standards acceptable to the State of Alaska
  with the right snow load and wind zone you need.
</p>

<h2>How does Energy Star help me in the winters?</h2>

<p>
  Heritage Home Center are experts building Energy Star homes that are specially
  suited for Western Washington weather. Stay warm in the winter and cool in the
  summer. See more information on our Energy Star homes.
  <a href="https://heritagehomecenter.com/energy-efficient-manufactured-homes"
    >Energy efficient manufactured homes</a
  >
</p>

<div class="office_pic_row_background">
  <div class="office_pic_row">
    <div class="it_is_our">Concrete driveway.</div>

    <img
      src="https://hhcwebsitedocs.blob.core.windows.net/media/20241017_172309.jpg"
      class="office_pic"
      alt="Manufactured Home for Alaska shipment"
    />
  </div>
</div>

<h2>Do I own the home outright?</h2>
<p>
  Of course! You are purchasing the home and also the right to lease this
  location within a senior's community that is rent controlled by Washington
  State.
</p>

<div class="office_pic_row_background">
  <div class="office_pic_row">
    <img
      src="https://hhcwebsitedocs.blob.core.windows.net/media/20241017_172407.jpg"
      class="office_pic"
      alt="Manufactured Home for Alaska shipment"
    />

    <div class="it_is_our">description of picture</div>
  </div>
</div>
<h2>What is included in the Park rent?</h2>
<p>
  the rent controlled location offers great value today and protection from
  future rent increases. The rent includes water, sewer and garbage pickup.
</p>
<div class="office_pic_row_background">
  <div class="office_pic_row">
    <div class="it_is_our">Concrete driveway.</div>

    <img
      src="https://hhcwebsitedocs.blob.core.windows.net/media/20241017_172434.jpg"
      class="office_pic"
      alt="Manufactured Home for Alaska shipment"
    />
  </div>
</div>
<h2>How do I get a mortgage?</h2>
<p>
  Conventional financing is availble at competitive rates. Please call our
  office and speak to one of our expert salespersons.
</p>
<div class="office_pic_row_background">
  <div class="office_pic_row">
    <img
      src="https://hhcwebsitedocs.blob.core.windows.net/media/20241023_113304.jpg"
      class="office_pic"
      alt="Manufactured Home for Alaska shipment"
    />

    <div class="it_is_our">description of picture</div>
  </div>
</div>
<h2>Question?</h2>
<p>Answer</p>

<div class="office_pic_row_background">
  <div class="office_pic_row">
    <div class="it_is_our">Concrete driveway.</div>

    <img
      src="https://hhcwebsitedocs.blob.core.windows.net/media/20241023_113058.jpg"
      class="office_pic"
      alt="Manufactured Home for Alaska shipment"
    />
  </div>
</div>
<h2>Question?</h2>
<p>Answer</p>
<div class="office_pic_row_background">
  <div class="office_pic_row">
    <img
      src="https://hhcwebsitedocs.blob.core.windows.net/media/20241023_113224.jpg"
      class="office_pic"
      alt="Manufactured Home for Alaska shipment"
    />

    <div class="it_is_our">description of picture</div>
  </div>
</div>
<h2>Question?</h2>
<p>Answer</p>

<div class="office_pic_row_background">
  <div class="office_pic_row">
    <div class="it_is_our">Concrete driveway.</div>

    <img
      src="https://hhcwebsitedocs.blob.core.windows.net/media/20241023_113532.jpg"
      class="office_pic"
      alt="Manufactured Home for Alaska shipment"
    />
  </div>
</div>
<h2>Question?</h2>
<p>Answer</p>
<div class="office_pic_row_background">
  <div class="office_pic_row">
    <img
      src="https://hhcwebsitedocs.blob.core.windows.net/media/20241023_113843.jpg"
      class="office_pic"
      alt="Manufactured Home for Alaska shipment"
    />

    <div class="it_is_our">description of picture</div>
  </div>
</div>
<h2>Question?</h2>
<p>Answer</p>

<div class="office_pic_row_background">
  <div class="office_pic_row">
    <div class="it_is_our">Concrete driveway.</div>

    <img
      src="https://hhcwebsitedocs.blob.core.windows.net/media/20241023_113848.jpg"
      class="office_pic"
      alt="Manufactured Home for Alaska shipment"
    />
  </div>
</div>
<h2>Question?</h2>
<p>Answer</p>
<div class="office_pic_row_background">
  <div class="office_pic_row">
    <img
      src="https://hhcwebsitedocs.blob.core.windows.net/media/20241023_113910.jpg"
      class="office_pic"
      alt="Manufactured Home for Alaska shipment"
    />

    <div class="it_is_our">description of picture</div>
  </div>
</div>
<h2>Question?</h2>
<p>Answer</p>

<div class="office_pic_row_background">
  <div class="office_pic_row">
    <div class="it_is_our">Concrete driveway.</div>

    <img
      src="https://hhcwebsitedocs.blob.core.windows.net/media/20241023_113919.jpg"
      class="office_pic"
      alt="Manufactured Home for Alaska shipment"
    />
  </div>
</div>
