import { Component } from '@angular/core';

@Component({
  selector: 'app-landpackage',
  templateUrl: './landpackage.component.html',
  styleUrls: ['./landpackage.component.scss'],
})
export class LandpackageComponent {
  constructor() {}
  ngOnInit() {}
}
